import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const systemInfoElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: false,
    className: "info-modal__element",
    title: "Логин",
    tooltip: "Логин для входа в Систему",
    value: fields.USER_LOGIN,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Роли",
    className: "info-modal__element",
    tooltip: "Роли пользователя",
    value: fields.USER_ROLES,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пароль",
    className: "info-modal__element",
    tooltip: "Пароль для входа в Систему",
    value: fields.USER_PASSWORD,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    className: "info-modal__element",
    tooltip: "Может принимать следующие значения: Активен, Удалён, Заблокирован",
    value: fields.USER_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заблокирован до",
    className: "info-modal__element",
    tooltip: "Дата, до которой заблокирована учётная запись пользователя",
    value: fields.BLOCKED_UNTIL,
  },
];

export const baseModalElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: true,
    isRequired: true,
    title: "ФИО",
    className: "info-modal__element_title",
    tooltip: "ФИО пользователя",
    value: fields.USER_FULL_NAME,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Телефон",
    className: "info-modal__element_title",
    tooltip: "Номер телефона пользователя",
    value: fields.USER_PHONE,
    mask: cloneDeep(standardMask.phone),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Электронная почта",
    className: "info-modal__element_title",
    tooltip: "Электронная почта пользователя",
    value: fields.USER_EMAIL,
    isRequired: true,
    mask: cloneDeep(standardMask.email),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Подразделение",
    className: "info-modal__element_title",
    tooltip: "Структурное подразделение пользователя",
    value: fields.USER_DIVISION,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    title: "Должность",
    className: "info-modal__element_full-size",
    tooltip: "Должность пользователя",
    value: fields.USER_POSITION,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    slotName: "userPosition",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Мессенджер",
    className: "info-modal__element_title",
    tooltip: "Мессенджер",
    value: fields.USER_MESSENGER,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];

export const techElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    className: "info-modal__element_title",
    tooltip: "Идентификатор пользователя",
    value: fields.USER_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, сохранивший запись",
    className: "d-flex info-modal__element_title",
    tooltip: "Пользователь, сохранивший запись",
    value: fields.USER_PARENT,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата сохранения",
    className: "d-flex info-modal__element",
    tooltip: "Дата сохранения записи",
    value: fields.USER_SAVE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    className: "d-flex info-modal__element_title",
    tooltip: "Пользователь, изменивший запись",
    value: fields.USER_EDIT_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последнего обновления",
    className: "d-flex info-modal__element",
    tooltip: "Дата последнего обновления",
    value: fields.USER_EDIT_DATE,
  },
];

export const fullModalElements: IModalElementType[] = [
  ...systemInfoElements,
  ...baseModalElements,
  ...techElements,
  {
    id: uuid(),
    title: "Редактирование / создание пользователя",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление пользователя",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];
